var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/FooterNav/FooterNav.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/FooterNav/FooterNav.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"FooterNav\">\n        <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),0)),"link")),"url"), env.opts.autoescape);
t_5 += "\" class=\"FooterNav-item FooterNav-item--search u-Route\">\n            <div class=\"FooterNav-itemContent\">\n                <div class=\"FooterNav-itemImage\">\n                    <img src=\"";
t_5 += runtime.suppressValue((lineno = 7, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/footerNav/bg-footer-search.png"])), env.opts.autoescape);
t_5 += "\">\n                </div>\n                <div class=\"FooterNav-itemInfo\">\n                    <div class=\"FooterNav-itemInfoTitle\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),0)),"title"))), env.opts.autoescape);
t_5 += "</div>\n                    <div class=\"FooterNav-itemInfoDescr\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),0)),"text")), env.opts.autoescape);
t_5 += "</div>\n                </div>\n                <div class=\"FooterNav-arrow\">";
t_5 += runtime.suppressValue((lineno = 13, colno = 69, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "</div>\n            </div>\n        </a>\n        <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),1)),"link")),"url"), env.opts.autoescape);
t_5 += "\" class=\"FooterNav-item FooterNav-item--tech u-Route\">\n            <div class=\"FooterNav-itemContent\">\n                <div class=\"FooterNav-itemImage\">\n                    <img src=\"";
t_5 += runtime.suppressValue((lineno = 19, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/footerNav/bg-footer-tech.png"])), env.opts.autoescape);
t_5 += "\">\n                </div>\n                <div class=\"FooterNav-itemInfo\">\n                    <div class=\"FooterNav-itemInfoTitle\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),1)),"title"))), env.opts.autoescape);
t_5 += "</div>\n                    <div class=\"FooterNav-itemInfoDescr\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),1)),"text")), env.opts.autoescape);
t_5 += "</div>\n                </div>\n                <div class=\"FooterNav-arrow\">";
t_5 += runtime.suppressValue((lineno = 25, colno = 69, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
t_5 += "</div>\n            </div>\n        </a>\n        <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),2)),"link")),"url"), env.opts.autoescape);
t_5 += "\" class=\"FooterNav-item FooterNav-item--dev u-Route\">\n            <div class=\"FooterNav-itemContent\">\n                <div class=\"FooterNav-itemImage\">\n                    <img src=\"";
t_5 += runtime.suppressValue((lineno = 31, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/footerNav/bg-footer-dev.png"])), env.opts.autoescape);
t_5 += "\">\n                </div>\n                <div class=\"FooterNav-itemInfo\">\n                    <div class=\"FooterNav-itemInfoTitle\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),2)),"title"))), env.opts.autoescape);
t_5 += "</div>\n                    <div class=\"FooterNav-itemInfoDescr\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),2)),"text")), env.opts.autoescape);
t_5 += "</div>\n                </div>\n                <div class=\"FooterNav-arrow\">";
t_5 += runtime.suppressValue((lineno = 37, colno = 69, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
t_5 += "</div>\n            </div>\n        </a>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("FooterNav");
context.setVariable("FooterNav", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/FooterNav/FooterNav.jinja"] , dependencies)