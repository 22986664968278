var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/SearchOurExpertise/SearchOurExpertise.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/SearchOurExpertise/SearchOurExpertise.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "reachTitle", "reachText"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("reachTitle", Object.prototype.hasOwnProperty.call(kwargs, "reachTitle") ? kwargs["reachTitle"] : "");frame.set("reachText", Object.prototype.hasOwnProperty.call(kwargs, "reachText") ? kwargs["reachText"] : "");var t_5 = "";t_5 += "\n    <div class=\"SearchOurExpertise Block leftCirclePoint\">\n        <div class=\"SearchOurExpertise-wrapper\">\n            <div class=\"SearchOurExpertise-title\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_5 += "</div>\n            <div class=\"SearchOurExpertise-content\">\n                <div class=\"SearchOurExpertise-description\">\n                    ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"descr")), env.opts.autoescape);
t_5 += "\n                </div>\n                <div class=\"SearchOurExpertise-linkList\">\n                    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"industries") && env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"industries")),"categories"))) {
t_5 += "\n                        <div class=\"SearchOurExpertise-linkListSection\">\n                            ";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"industries")),"categories");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("link", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                                <a href=\"";
t_5 += runtime.suppressValue((lineno = 14, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-industries"])), env.opts.autoescape);
t_5 += "#";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"slug"), env.opts.autoescape);
t_5 += "\"\n                                   class=\"SearchOurExpertise-link u-Route\">\n                                    <div class=\"SearchOurExpertise-linkTitle\">\n                                        ";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
t_5 += "\n                                    </div>\n                                    <div class=\"SearchOurExpertise-linkArrow\">\n                                        ";
t_5 += runtime.suppressValue((lineno = 20, colno = 64, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
t_5 += "\n                                    </div>\n                                </a>\n                            ";
;
}
}
frame = frame.pop();
t_5 += "\n                            <div class=\"SearchOurExpertise-more\">\n                                <a href=\"";
t_5 += runtime.suppressValue((lineno = 25, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-industries"])), env.opts.autoescape);
t_5 += "\" class=\"u-Route\">learn more</a>\n                            </div>\n                        </div>\n                    ";
;
}
t_5 += "\n                    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"positions") && env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"positions")),"categories"))) {
t_5 += "\n                        <div class=\"SearchOurExpertise-linkListSection\">\n                            ";
frame = frame.push();
var t_14 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blocks")),"positions")),"categories");
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("link", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
t_5 += "\n                                <a href=\"";
t_5 += runtime.suppressValue((lineno = 32, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-positions"])), env.opts.autoescape);
t_5 += "#";
t_5 += runtime.suppressValue(runtime.memberLookup((t_15),"slug"), env.opts.autoescape);
t_5 += "\"\n                                   class=\"SearchOurExpertise-link u-Route\">\n                                    <div class=\"SearchOurExpertise-linkTitle\">\n                                        ";
t_5 += runtime.suppressValue(runtime.memberLookup((t_15),"title"), env.opts.autoescape);
t_5 += "\n                                    </div>\n                                    <div class=\"SearchOurExpertise-linkArrow\">\n                                        ";
t_5 += runtime.suppressValue((lineno = 38, colno = 64, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_16 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_17 = "";;
frame = frame.pop();
return new runtime.SafeString(t_17);
});
return macro_t_16;})()})])), env.opts.autoescape);
t_5 += "\n                                    </div>\n                                </a>\n                            ";
;
}
}
frame = frame.pop();
t_5 += "\n                            <div class=\"SearchOurExpertise-more\">\n                                <a href=\"";
t_5 += runtime.suppressValue((lineno = 43, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-positions"])), env.opts.autoescape);
t_5 += "\" class=\"u-Route\">learn more</a>\n                            </div>\n                        </div>\n                    ";
;
}
t_5 += "\n                </div>\n\n\n            </div>\n\n            <div class=\"SearchOurExpertise-title SearchOurExpertise-titleReach\">";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "reachTitle"), env.opts.autoescape);
t_5 += "</div>\n            <div class=\"SearchOurExpertise-reachContent\">\n                <div class=\"SearchOurExpertise-reachText\">\n                    ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "reachText")), env.opts.autoescape);
t_5 += "\n\n                    <div class=\"SearchOurExpertise-more\">\n                        <a href=\"";
t_5 += runtime.suppressValue((lineno = 58, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-global-reach"])), env.opts.autoescape);
t_5 += "\" class=\"u-Route\">learn more</a>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("SearchOurExpertise");
context.setVariable("SearchOurExpertise", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/SearchOurExpertise/SearchOurExpertise.jinja"] , dependencies)