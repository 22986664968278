var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonItemShape/CommonItemShape.jinja"] = require( "front/components/CommonItemShape/CommonItemShape.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/JoinIntro/JoinIntro.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/JoinIntro/JoinIntro.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonItemShape/CommonItemShape.jinja", false, "assets/app/front/components/JoinIntro/JoinIntro.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonItemShape")) {
var t_7 = t_4.CommonItemShape;
} else {
cb(new Error("cannot import 'CommonItemShape'")); return;
}
context.setVariable("CommonItemShape", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_9 = "";t_9 += "\n    ";
var t_10;
t_10 = 6;
frame.set("delta", t_10, true);
if(frame.topLevel) {
context.setVariable("delta", t_10);
}
if(frame.topLevel) {
context.addExport("delta", t_10);
}
t_9 += "\n    ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"items")) < 4) {
t_9 += "\n        ";
var t_11;
t_11 = 3;
frame.set("delta", t_11, true);
if(frame.topLevel) {
context.setVariable("delta", t_11);
}
if(frame.topLevel) {
context.addExport("delta", t_11);
}
t_9 += "\n    ";
;
}
t_9 += "\n\n    <div class=\"JoinIntro\">\n        <div class=\"JoinIntro-sidebar leftCirclePoint\"></div>\n        <div class=\"JoinIntro-body\">\n            <div class=\"JoinIntro-title\">\n                ";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_9 += "\n            </div>\n            <div class=\"JoinIntro-section\">\n                ";
frame = frame.push();
var t_14 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"items");
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("item", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
t_9 += "\n                <div class=\"JoinIntro-sectionBlock\">\n                    ";
t_9 += runtime.suppressValue((lineno = 20, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonItemShape"), "CommonItemShape", context, [t_15,true,runtime.makeKeywordArgs({"isGray": true})])), env.opts.autoescape);
t_9 += "\n                </div>\n";
t_9 += "\n                ";
;
}
}
frame = frame.pop();
t_9 += "\n                    ";
frame = frame.push();
var t_18 = (lineno = 25, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [runtime.contextOrFrameLookup(context, frame, "delta") - env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"items"))]));
if(t_18) {t_18 = runtime.fromIterator(t_18);
var t_17 = t_18.length;
for(var t_16=0; t_16 < t_18.length; t_16++) {
var t_19 = t_18[t_16];
frame.set("i", t_19);
frame.set("loop.index", t_16 + 1);
frame.set("loop.index0", t_16);
frame.set("loop.revindex", t_17 - t_16);
frame.set("loop.revindex0", t_17 - t_16 - 1);
frame.set("loop.first", t_16 === 0);
frame.set("loop.last", t_16 === t_17 - 1);
frame.set("loop.length", t_17);
t_9 += "\n                        <div class=\"JoinIntro-sectionBlock JoinIntro-sectionBlock--empty\"></div>\n                    ";
;
}
}
frame = frame.pop();
t_9 += "\n";
t_9 += "\n            </div>\n";
t_9 += "\n        </div>\n        <div class=\"JoinIntro-sidebar\"></div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("JoinIntro");
context.setVariable("JoinIntro", macro_t_8);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/JoinIntro/JoinIntro.jinja"] , dependencies)