var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/utils/macros.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["id"], 
["bound", "title"], 
function (l_id, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("id", l_id);
frame.set("bound", Object.prototype.hasOwnProperty.call(kwargs, "bound") ? kwargs["bound"] : "");frame.set("title", Object.prototype.hasOwnProperty.call(kwargs, "title") ? kwargs["title"] : "");var t_2 = "";t_2 += "<svg";
if(runtime.contextOrFrameLookup(context, frame, "bound")) {
t_2 += " viewBox=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "bound"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += ">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "title")) {
t_2 += "<title>";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_2 += "</title>";
;
}
t_2 += "\n        <use xlink:href=\"/static/svg/front.sprite.svg#";
t_2 += runtime.suppressValue(l_id, env.opts.autoescape);
t_2 += "\"></use>\n    </svg>";
t_2 += runtime.suppressValue((lineno = 4, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("svgSprite");
context.setVariable("svgSprite", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["component"], 
["mods", "classes", "data"], 
function (l_component, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("component", l_component);
frame.set("mods", Object.prototype.hasOwnProperty.call(kwargs, "mods") ? kwargs["mods"] : []);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_4 = "";if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item")) {
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item");
frame.set("item", t_5, true);
if(frame.topLevel) {
context.setVariable("item", t_5);
}
if(frame.topLevel) {
context.addExport("item", t_5);
}
;
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate(l_component, false, "assets/app/front/utils/macros.jinja", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(result, callback){
t_4 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_4 += runtime.suppressValue((lineno = 11, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
});
frame = callerFrame;
return new runtime.SafeString(t_4);
});
context.addExport("includeComponent");
context.setVariable("includeComponent", macro_t_3);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/utils/macros.jinja"] , dependencies)