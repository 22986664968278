var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonBlock/CommonBlock.jinja"] = require( "front/components/CommonBlock/CommonBlock.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/NewsPopup/NewsPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/NewsPopup/NewsPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonBlock/CommonBlock.jinja", false, "assets/app/front/pages/NewsPopup/NewsPopup.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonBlock")) {
var t_7 = t_4.CommonBlock;
} else {
cb(new Error("cannot import 'CommonBlock'")); return;
}
context.setVariable("CommonBlock", t_7);
output += "\n\n<div class=\"NewsPopup";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")) {
output += " hasCover";
;
}
output += "\">\n    <div class=\"NewsPopup-bg\"></div>\n    <div class=\"NewsPopup-wrapper overflowLock\">\n        <div class=\"NewsPopup-content\">\n            <div class=\"NewsPopup-contentInner\">\n                <div class=\"NewsPopup-close\">\n                    <div class=\"NewsPopup-closeItem NewsPopup-closeItem--1\"></div>\n                    <div class=\"NewsPopup-closeItem NewsPopup-closeItem--2\"></div>\n                </div>\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")) {
output += "\n                    <div class=\"NewsPopup-cover\">\n                        <div class=\"NewsPopup-coverImg\">\n                            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")),"w1260_url"), env.opts.autoescape);
output += "\"\n                                 srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")),"w1440_url"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")),"w1260_url"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")),"w840_url"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"cover")),"w720_url"), env.opts.autoescape);
output += " 720w\"\n                                 sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                                 alt=\"\">\n                        </div>\n                    </div>\n                ";
;
}
output += "\n                <div class=\"NewsPopup-header\">\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"tag")) {
output += "<div class=\"NewsPopup-headerTag\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"tag"), env.opts.autoescape);
output += "</div>";
;
}
output += "\n                    <div class=\"NewsPopup-headerTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"title"), env.opts.autoescape);
output += "</div>\n                    <div class=\"NewsPopup-headerDate\">Published ";
output += runtime.suppressValue(env.getFilter("datetime").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"date"),"%B %d, %Y"), env.opts.autoescape);
output += "</div>\n                </div>\n                ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"blocks")) && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"blocks")) {
output += "\n                    <div class=\"NewsPopup-blocks\">\n                        <div class=\"NewsPopup-blocksWrapper\">\n                            ";
frame = frame.push();
var t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"blocks");
if(t_10) {t_10 = runtime.fromIterator(t_10);
var t_9 = t_10.length;
for(var t_8=0; t_8 < t_10.length; t_8++) {
var t_11 = t_10[t_8];
frame.set("block", t_11);
frame.set("loop.index", t_8 + 1);
frame.set("loop.index0", t_8);
frame.set("loop.revindex", t_9 - t_8);
frame.set("loop.revindex0", t_9 - t_8 - 1);
frame.set("loop.first", t_8 === 0);
frame.set("loop.last", t_8 === t_9 - 1);
frame.set("loop.length", t_9);
output += "\n                                <div class=\"NewsPopup-blocksBlock NewsPopup-blocksBlock--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n                                    ";
output += runtime.suppressValue((lineno = 37, colno = 50, runtime.callWrap(t_7, "CommonBlock", context, [t_11,runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\n                                </div>\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n                ";
;
}
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/NewsPopup/NewsPopup.jinja"] , dependencies)