var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/IndexNews/IndexNews.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/IndexNews/IndexNews.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "isFull", "blockTitle"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("isFull", Object.prototype.hasOwnProperty.call(kwargs, "isFull") ? kwargs["isFull"] : false);frame.set("blockTitle", Object.prototype.hasOwnProperty.call(kwargs, "blockTitle") ? kwargs["blockTitle"] : "news");var t_5 = "";t_5 += "\n    <div class=\"IndexNews Block leftCirclePoint ";
if(!runtime.contextOrFrameLookup(context, frame, "isFull")) {
t_5 += " dark";
;
}
t_5 += "\">\n        <div class=\"IndexNews-wrapper\">\n            <div class=\"IndexNews-title\">";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "blockTitle"), env.opts.autoescape);
t_5 += "</div>\n            <div class=\"IndexNews-content\">\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 7, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:news-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"id"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"slug")]})])), env.opts.autoescape);
t_5 += "\" class=\"IndexNews-news u-Route\">\n                    <div class=\"IndexNews-newsDescr\">\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tag")) {
t_5 += "<div class=\"IndexNews-newsDescrTag\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tag"), env.opts.autoescape);
t_5 += "</div>\n                        ";
;
}
t_5 += "<div class=\"IndexNews-newsDescrDate\">";
t_5 += runtime.suppressValue(env.getFilter("datetime").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"date"),"%m/%d/%Y"), env.opts.autoescape);
t_5 += "</div>\n                    </div>\n                    <div class=\"IndexNews-newsInfo\">\n                        <div class=\"IndexNews-newsInfoTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_5 += "</div>\n                        <div class=\"IndexNews-newsInfoArrow\">";
t_5 += runtime.suppressValue((lineno = 16, colno = 85, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-arrow-right",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "</div>\n                    </div>\n                </a>\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 19, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:news"])), env.opts.autoescape);
t_5 += "\" class=\"IndexNews-link u-Route\">\n                    all news\n                </a>\n            </div>\n        </div>\n\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("IndexNews");
context.setVariable("IndexNews", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/IndexNews/IndexNews.jinja"] , dependencies)