var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/RecentPlacements/RecentPlacementsListItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : []);var t_2 = "";t_2 += "\n<div class=\"RecentPlacements-listItem\">\n    <div class=\"RecentPlacements-listLogo\">\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")) {
t_2 += "<img src=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")),"w218_url"), env.opts.autoescape);
t_2 += "\"\n             srcset=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")),"w253_url"), env.opts.autoescape);
t_2 += " 1920w,\n                      ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")),"w218_url"), env.opts.autoescape);
t_2 += " 1680w,\n                      ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")),"w137_url"), env.opts.autoescape);
t_2 += " 1120w,\n                      ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"logo")),"w360_url"), env.opts.autoescape);
t_2 += " 720w\"\n             sizes=\"(max-width: 1119px) 1,  ";
t_2 += "\n                                         75%\" ";
t_2 += "\n             alt=\"\">\n        ";
;
}
t_2 += "</div>\n    <div class=\"RecentPlacements-listPosition\">\n        ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"position"), env.opts.autoescape);
t_2 += "\n    </div>\n    <div class=\"RecentPlacements-listLocation\">\n        ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"location"), env.opts.autoescape);
t_2 += "\n    </div>\n</div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("RecentPlacementsListItem");
context.setVariable("RecentPlacementsListItem", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/RecentPlacements/RecentPlacementsListItem.jinja"] , dependencies)