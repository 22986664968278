var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/OurStory/OurStory.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/OurStory/OurStory.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"OurStory Block leftCirclePoint\">\n        <div class=\"OurStory-wrapper\">\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n            <div class=\"OurStory-section\">\n                <div class=\"OurStory-title isNormal\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overviewTitle"), env.opts.autoescape);
t_5 += "</div>\n                <div class=\"OurStory-descr\">\n                    ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"overviewText")), env.opts.autoescape);
t_5 += "\n                </div>\n            </div>\n            <div class=\"OurStory-content\">\n                <div class=\"OurStory-containerYears\">\n                    <div class=\"OurStory-yearFix\">20</div>\n                </div>\n                ";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "timeline");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                    <div class=\"OurStory-section\">\n                        <div class=\"OurStory-year\">\n                            <span class=\"isNormal isHide\">20</span>\n                            <span class=\"curYear\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"yearShort"), env.opts.autoescape);
t_5 += "</span>\n                        </div>\n                        <div class=\"OurStory-text";
if(!runtime.memberLookup((t_9),"text")) {
t_5 += " noText";
;
}
t_5 += "\">\n                            ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_9),"text")), env.opts.autoescape);
t_5 += "\n\n                            ";
if(runtime.memberLookup((t_9),"image") || runtime.memberLookup((t_9),"image2")) {
t_5 += "\n                                <div class=\"OurStory-textImgWrapper";
if(runtime.memberLookup((t_9),"image") && runtime.memberLookup((t_9),"image2")) {
t_5 += " moreImgs";
;
}
t_5 += "\">\n                                    ";
if(runtime.memberLookup((t_9),"image")) {
t_5 += "<div class=\"OurStory-textImg\">\n                                            <img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w535_url"), env.opts.autoescape);
t_5 += "\"\n                                                 srcset=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w630_url"), env.opts.autoescape);
t_5 += " 1920w,\n                                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w535_url"), env.opts.autoescape);
t_5 += " 1680w,\n                                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w320_url"), env.opts.autoescape);
t_5 += " 1120w,\n                                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w600_url"), env.opts.autoescape);
t_5 += " 720w\"\n                                        sizes=\"(max-width: 1119px) 1, ";
t_5 += "\n                                                75%\" ";
t_5 += "\n                                                 alt=\"\">\n                                        </div>\n                                    ";
;
}
t_5 += "\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n                                </div>\n                            ";
;
}
t_5 += "\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
t_5 += "\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("OurStory");
context.setVariable("OurStory", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/OurStory/OurStory.jinja"] , dependencies)