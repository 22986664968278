var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/SearchClientsPopup/SearchClientsPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/SearchClientsPopup/SearchClientsPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
output += "\n\n<div class=\"SearchClientsPopup\">\n    <div class=\"SearchClientsPopup-wrapper overflowLock\">\n        <div class=\"SearchClientsPopup-bg\"></div>\n        <div class=\"SearchClientsPopup-content\">\n            <div class=\"SearchClientsPopup-contentInner\">\n                <div class=\"SearchClientsPopup-close\">\n                    <div class=\"SearchClientsPopup-closeItem SearchClientsPopup-closeItem--1\"></div>\n                    <div class=\"SearchClientsPopup-closeItem SearchClientsPopup-closeItem--2\"></div>\n                </div>\n                <div class=\"SearchClientsPopup-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"title"), env.opts.autoescape);
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"regions")) === true) {
output += " clients";
;
}
output += "</div>\n                ";
if(!env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"regions")) === true) {
output += "<div class=\"SearchClientsPopup-description\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"description")), env.opts.autoescape);
output += "</div>\n                ";
;
}
output += "<div class=\"SearchClientsPopup-clients\">\n                    ";
frame = frame.push();
var t_6 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"selectedClients");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("client", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n                        <a class=\"SearchClientsPopup-client ";
if(runtime.memberLookup((t_7),"link")) {
output += " isCaseStudy";
;
}
output += "\"\n                           ";
if(runtime.memberLookup((t_7),"link")) {
output += "href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_7),"link"), env.opts.autoescape);
output += "\"";
;
}
output += " target=\"_blank\" rel=\"nofollow\">\n                            <div class=\"SearchClientsPopup-clientLogo\">\n                                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_7),"logo")),"lightUrl"), env.opts.autoescape);
output += "\"\n                                     srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_7),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1920w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_7),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1680w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_7),"logo")),"lightUrl"), env.opts.autoescape);
output += " 1120w,\n                                          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_7),"logo")),"lightUrl"), env.opts.autoescape);
output += " 720w\"\n                                     sizes=\"(max-width: 1119px) 1, ";
output += "\n                                         75%\" ";
output += "\n                                     alt=\"\">\n                            </div>\n                            ";
if(runtime.memberLookup((t_7),"link")) {
output += "\n                                <div class=\"SearchClientsPopup-clientCaseStudy\">\n                                    ";
output += runtime.suppressValue((lineno = 32, colno = 60, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-file",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
output += "\n                                    <span>case study</span>\n                                </div>\n                            ";
;
}
output += "\n                        </a>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/SearchClientsPopup/SearchClientsPopup.jinja"] , dependencies)