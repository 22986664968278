var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TeamMembers/TeamMembersList.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/TeamMembers/TeamMembersList.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["membersList", "values"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("membersList", Object.prototype.hasOwnProperty.call(kwargs, "membersList") ? kwargs["membersList"] : {});frame.set("values", Object.prototype.hasOwnProperty.call(kwargs, "values") ? kwargs["values"] : {});var t_5 = "";t_5 += "\n    <div class=\"TeamMembers-list ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "values")),"team") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "values")),"team")),"isPositionFilterNeeded")) {
t_5 += " isPositionFilter ";
;
}
t_5 += "\">\n        ";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "membersList");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("member", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n            <a class=\"u-Route\" href=\"";
t_5 += runtime.suppressValue((lineno = 5, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:team-member-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_9),"slug")]})])), env.opts.autoescape);
t_5 += "\">\n                <div class=\"TeamMembers-member\">\n                    <div class=\"TeamMembers-memberAvatar\">\n                        ";
if(runtime.memberLookup((t_9),"image")) {
t_5 += "\n                            <img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w332_url"), env.opts.autoescape);
t_5 += "\"\n                                 srcset=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w390_url"), env.opts.autoescape);
t_5 += " 1920w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w332_url"), env.opts.autoescape);
t_5 += " 1680w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w202_url"), env.opts.autoescape);
t_5 += " 1120w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"image")),"w308_url"), env.opts.autoescape);
t_5 += " 720w\"\n                                 sizes=\"(max-width: 1119px) 1, ";
t_5 += "\n                                         75%\" ";
t_5 += "\n                                 alt=\"\">\n                        ";
;
}
else {
t_5 += "\n                            <div class=\"TeamMembers-memberAvatarPlaceholder\">\n                                ";
var t_10;
t_10 = (lineno = 19, colno = 69, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_9),"name")),"split"), "member[\"name\"][\"split\"]", context, [" "]));
frame.set("memberName", t_10, true);
if(frame.topLevel) {
context.setVariable("memberName", t_10);
}
if(frame.topLevel) {
context.addExport("memberName", t_10);
}
t_5 += "\n                                <div class=\"TeamMembers-memberAvatarPlaceholderContent\">\n                                    <div>\n                                        <span>";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "memberName")),0)),0), env.opts.autoescape);
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "memberName")) > 1) {
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "memberName")),1)),0), env.opts.autoescape);
;
}
t_5 += "</span>\n                                    </div>\n                                </div>\n                            </div>\n                        ";
;
}
t_5 += "\n                    </div>\n                    <div class=\"TeamMembers-memberName\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"name"), env.opts.autoescape);
t_5 += "</div>\n                    <div class=\"TeamMembers-memberTeam\">\n                        ";
if(runtime.memberLookup((t_9),"team") && runtime.memberLookup((runtime.memberLookup((t_9),"team")),"value")) {
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"team")),"value"), env.opts.autoescape);
;
}
t_5 += "\n                        ";
if(runtime.memberLookup((t_9),"location")) {
t_5 += "<span> ";
if(runtime.memberLookup((t_9),"team") && runtime.memberLookup((runtime.memberLookup((t_9),"team")),"value")) {
t_5 += "&nbsp;/&nbsp;";
;
}
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"location")),"title"), env.opts.autoescape);
t_5 += "</span>";
;
}
t_5 += "\n                    </div>\n                    <div class=\"TeamMembers-memberPosition\">\n                        ";
if(runtime.memberLookup((t_9),"title")) {
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
t_5 += "\n                        ";
;
}
t_5 += "</div>\n                </div>\n            </a>\n        ";
;
}
}
frame = frame.pop();
t_5 += "\n        ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "membersList")) == 0) {
t_5 += "\n            <div class=\"TeamMembers-zeroResults\">0 results</div>\n        ";
;
}
t_5 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("TeamMembersList");
context.setVariable("TeamMembersList", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TeamMembers/TeamMembersList.jinja"] , dependencies)