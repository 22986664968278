var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonItemShape/CommonItemShape.jinja"] = require( "front/components/CommonItemShape/CommonItemShape.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/GrowingTalant/GrowingTalant.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/GrowingTalant/GrowingTalant.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonItemShape/CommonItemShape.jinja", false, "assets/app/front/components/GrowingTalant/GrowingTalant.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonItemShape")) {
var t_7 = t_4.CommonItemShape;
} else {
cb(new Error("cannot import 'CommonItemShape'")); return;
}
context.setVariable("CommonItemShape", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["data", "isSearch"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("isSearch", Object.prototype.hasOwnProperty.call(kwargs, "isSearch") ? kwargs["isSearch"] : false);var t_9 = "";t_9 += "\n    <div class=\"GrowingTalant\">\n        <div class=\"GrowingTalant-sidebar leftCirclePoint\"></div>\n        <div class=\"GrowingTalant-body\">\n            <div class=\"GrowingTalant-title\">\n                ";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_9 += "\n            </div>\n            <div class=\"GrowingTalant-section\">\n                ";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"items");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("item", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_9 += "\n                    <div class=\"GrowingTalant-sectionBlock\">\n                        ";
t_9 += runtime.suppressValue((lineno = 14, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonItemShape"), "CommonItemShape", context, [t_13,false,runtime.contextOrFrameLookup(context, frame, "isSearch")])), env.opts.autoescape);
t_9 += "\n                    </div>\n                ";
;
}
}
frame = frame.pop();
t_9 += "\n            </div>\n";
t_9 += "\n        </div>\n        <div class=\"GrowingTalant-sidebar\"></div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("GrowingTalant");
context.setVariable("GrowingTalant", macro_t_8);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/GrowingTalant/GrowingTalant.jinja"] , dependencies)