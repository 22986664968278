var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/SearchExtraMenu/SearchExtraMenu.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/SearchExtraMenu/SearchExtraMenu.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += "\n    <div class=\"SearchExtraMenu isHide\">\n        <div class=\"SearchExtraMenu-content\">\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 5, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item u-Route\" data-id=\"search\">overview</a>\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 6, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-global-reach"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item u-Route\" data-id=\"global-reach\">global reach</a>\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 7, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-industries"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item u-Route\" data-id=\"sectors\">sectors</a>\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 8, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-positions"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item u-Route\" data-id=\"positions\">functions</a>\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 9, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-asset-classes"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item u-Route\" data-id=\"asset-classes\">asset classes</a>\n            <a href=\"";
t_5 += runtime.suppressValue((lineno = 10, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-team"])), env.opts.autoescape);
t_5 += "\" class=\"SearchExtraMenu-item SearchExtraMenu-item--team u-Route\" data-id=\"team\">search team</a>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("SearchExtraMenu");
context.setVariable("SearchExtraMenu", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/SearchExtraMenu/SearchExtraMenu.jinja"] , dependencies)