var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/SearchUniversalBlock/SearchUniversalBlock.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/SearchUniversalBlock/SearchUniversalBlock.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "page"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("page", Object.prototype.hasOwnProperty.call(kwargs, "page") ? kwargs["page"] : "");var t_5 = "";t_5 += "\n    <div class=\"SearchUniversalBlock\" data-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"slug"), env.opts.autoescape);
t_5 += "\">\n        <div class=\"SearchUniversalBlock-content\">\n            <div class=\"SearchUniversalBlock-line\">\n                <div class=\"SearchUniversalBlock-leftSide\">\n                    <div class=\"SearchUniversalBlock-title\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_5 += "</div>\n                    <ul class=\"SearchUniversalBlock-industries\">\n                        ";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"industries");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("industry", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                            <li class=\"SearchUniversalBlock-industry\">\n                                ";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
t_5 += "\n                            </li>\n                        ";
;
}
}
frame = frame.pop();
t_5 += "\n                        ";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"regions");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("region", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_5 += "\n                            <li class=\"SearchUniversalBlock-region\">\n                                <a href=\"";
t_5 += runtime.suppressValue((lineno = 16, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-global-reach-region-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"slug"),runtime.memberLookup((t_13),"slug")]})])), env.opts.autoescape);
t_5 += "\" class=\"SearchUniversalBlock-viewSelected u-Route\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_13),"title"), env.opts.autoescape);
t_5 += "</a>\n                            </li>\n                        ";
;
}
}
frame = frame.pop();
t_5 += "\n                    </ul>\n\n                </div>\n                <div class=\"SearchUniversalBlock-rightSide\">\n                    <div class=\"SearchUniversalBlock-description\">";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"description")), env.opts.autoescape);
t_5 += "</div>\n                </div>\n            </div>\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"selectedClients") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"featuredClients") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"speciality")) {
t_5 += "<div class=\"SearchUniversalBlock-line SearchUniversalBlock-lineCenterAlign\">\n                    <div class=\"SearchUniversalBlock-leftSide\">\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"speciality")) {
t_5 += "<a href=\"";
t_5 += runtime.suppressValue((lineno = 30, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-team"])), env.opts.autoescape);
t_5 += "#teamMembers/\"\n                               class=\"SearchUniversalBlock-viewSelected SearchUniversalBlock-viewTeam u-Route\" data-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"speciality"), env.opts.autoescape);
t_5 += "\">view team</a>\n                        ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"selectedClients")) {
t_5 += "<a href=\"";
t_5 += runtime.suppressValue((lineno = 34, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-" + runtime.contextOrFrameLookup(context, frame, "page") + "-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"slug")]})])), env.opts.autoescape);
t_5 += "\"\n                               class=\"SearchUniversalBlock-viewSelected u-Route\">view selected clients</a>\n                        ";
;
}
t_5 += "</div>\n                    <div class=\"SearchUniversalBlock-rightSide\">\n                        <div class=\"SearchUniversalBlock-featuredClients\">\n                            ";
frame = frame.push();
var t_16 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"featuredClients");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("client", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
t_5 += "\n                                <div class=\"SearchUniversalBlock-featuredClient\">\n                                    <img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"logo")),"darkUrl"), env.opts.autoescape);
t_5 += "\"\n                                         srcset=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"logo")),"darkUrl"), env.opts.autoescape);
t_5 += " 1920w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"logo")),"darkUrl"), env.opts.autoescape);
t_5 += " 1680w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"logo")),"darkUrl"), env.opts.autoescape);
t_5 += " 1120w,\n                                          ";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"logo")),"darkUrl"), env.opts.autoescape);
t_5 += " 720w\"\n                                         sizes=\"(max-width: 1119px) 1, ";
t_5 += "\n                                         75%\" ";
t_5 += "\n                                         alt=\"\">\n                                    <a href=\"";
t_5 += runtime.suppressValue((lineno = 50, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search-" + runtime.contextOrFrameLookup(context, frame, "page") + "-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"slug")]})])), env.opts.autoescape);
t_5 += "\"\n                                       class=\"u-Route\"></a>\n                                </div>\n                            ";
;
}
}
frame = frame.pop();
t_5 += "\n                        </div>\n                    </div>\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n";
t_5 += "\n                </div>\n            ";
;
}
t_5 += "</div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("SearchUniversalBlock");
context.setVariable("SearchUniversalBlock", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/SearchUniversalBlock/SearchUniversalBlock.jinja"] , dependencies)