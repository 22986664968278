var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/GrowingTalant/GrowingTalant.jinja"] = require( "front/components/GrowingTalant/GrowingTalant.jinja" );
dependencies["front/components/OurServices/OurServices.jinja"] = require( "front/components/OurServices/OurServices.jinja" );
dependencies["front/components/Nutshell/Nutshell.jinja"] = require( "front/components/Nutshell/Nutshell.jinja" );
dependencies["front/components/TeamMembers/TeamMembers.jinja"] = require( "front/components/TeamMembers/TeamMembers.jinja" );
dependencies["front/components/IndexNews/IndexNews.jinja"] = require( "front/components/IndexNews/IndexNews.jinja" );
dependencies["front/components/FooterNav/FooterNav.jinja"] = require( "front/components/FooterNav/FooterNav.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Development/DevelopmentSection.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n\n";
env.getTemplate("front/components/GrowingTalant/GrowingTalant.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "GrowingTalant")) {
var t_10 = t_7.GrowingTalant;
} else {
cb(new Error("cannot import 'GrowingTalant'")); return;
}
context.setVariable("GrowingTalant", t_10);
output += "\n";
env.getTemplate("front/components/OurServices/OurServices.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "OurServices")) {
var t_14 = t_11.OurServices;
} else {
cb(new Error("cannot import 'OurServices'")); return;
}
context.setVariable("OurServices", t_14);
output += "\n";
env.getTemplate("front/components/Nutshell/Nutshell.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "Nutshell")) {
var t_18 = t_15.Nutshell;
} else {
cb(new Error("cannot import 'Nutshell'")); return;
}
context.setVariable("Nutshell", t_18);
output += "\n";
env.getTemplate("front/components/TeamMembers/TeamMembers.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(Object.prototype.hasOwnProperty.call(t_19, "TeamMembers")) {
var t_22 = t_19.TeamMembers;
} else {
cb(new Error("cannot import 'TeamMembers'")); return;
}
context.setVariable("TeamMembers", t_22);
output += "\n";
env.getTemplate("front/components/IndexNews/IndexNews.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(Object.prototype.hasOwnProperty.call(t_23, "IndexNews")) {
var t_26 = t_23.IndexNews;
} else {
cb(new Error("cannot import 'IndexNews'")); return;
}
context.setVariable("IndexNews", t_26);
output += "\n";
env.getTemplate("front/components/FooterNav/FooterNav.jinja", false, "assets/app/front/pages/Development/DevelopmentSection.jinja", false, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
t_27.getExported(function(t_29,t_27) {
if(t_29) { cb(t_29); return; }
if(Object.prototype.hasOwnProperty.call(t_27, "FooterNav")) {
var t_30 = t_27.FooterNav;
} else {
cb(new Error("cannot import 'FooterNav'")); return;
}
context.setVariable("FooterNav", t_30);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
output += t_31;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_34,t_33) {
if(t_34) { cb(t_34); return; }
output += t_33;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content_class"))(env, context, frame, runtime, function(t_36,t_35) {
if(t_36) { cb(t_36); return; }
output += t_35;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_38,t_37) {
if(t_38) { cb(t_38); return; }
output += t_37;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_40,t_39) {
if(t_40) { cb(t_40); return; }
output += t_39;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "true • development";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 17, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [(lineno = 17, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/headers/bg-dev-team.jpg"]))])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 20, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"true • development\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content_class(env, context, frame, runtime, cb) {
var lineno = 25;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "DevPage IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 27;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"DevSection PageSection\">\n    <div class=\"DevSection-wrapper NeedFixOnPopup\">\n        <div class=\"Content-outter isInitialState\">\n            <div class=\"Content-wrapper\">\n                ";
output += runtime.suppressValue((lineno = 32, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "GrowingTalant"), "GrowingTalant", context, [runtime.contextOrFrameLookup(context, frame, "progressiveTalent"),true])), env.opts.autoescape);
output += "\n                ";
output += runtime.suppressValue((lineno = 33, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "OurServices"), "OurServices", context, [runtime.contextOrFrameLookup(context, frame, "ourServices")])), env.opts.autoescape);
output += "\n                ";
output += runtime.suppressValue((lineno = 34, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Nutshell"), "Nutshell", context, [runtime.contextOrFrameLookup(context, frame, "searchNutshell"),true])), env.opts.autoescape);
output += "\n";
output += "\n                ";
if(runtime.contextOrFrameLookup(context, frame, "developmentNews")) {
output += runtime.suppressValue((lineno = 37, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "IndexNews"), "IndexNews", context, [runtime.contextOrFrameLookup(context, frame, "developmentNews"),true,runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "overview")),"newsTitle")])), env.opts.autoescape);
output += "\n                ";
;
}
output += "\n            </div>\n        </div>\n    </div>\n    <div class=\"Section-content\">\n        ";
context.getBlock("section_content")(env, context, frame, runtime, function(t_42,t_41) {
if(t_42) { cb(t_42); return; }
output += t_41;
output += "\n    </div>\n</div>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_section_content(env, context, frame, runtime, cb) {
var lineno = 44;
var colno = 11;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 49;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_43,hole_49) {
if(t_43) { cb(t_43); return; }
hole_49 = runtime.markSafe(hole_49);
output += "\n    ";
output += runtime.suppressValue(hole_49, env.opts.autoescape);
output += "\n    window.app.data.logos = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "searchNutshell")),"logos"))), env.opts.autoescape);
output += ";\n    window.app.data.team = 'development';\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_content_class: b_content_class,
b_content: b_content,
b_section_content: b_section_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Development/DevelopmentSection.jinja"] , dependencies)