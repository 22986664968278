var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonQuote/CommonQuote.jinja"] = require( "front/components/CommonQuote/CommonQuote.jinja" );
dependencies["front/components/CommonBlockVideo/CommonBlockVideo.jinja"] = require( "front/components/CommonBlockVideo/CommonBlockVideo.jinja" );
dependencies["front/components/CommonSlider/CommonSlider.jinja"] = require( "front/components/CommonSlider/CommonSlider.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CommonBlock/CommonBlock.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/CommonBlock/CommonBlock.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonQuote/CommonQuote.jinja", false, "assets/app/front/components/CommonBlock/CommonBlock.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonQuote")) {
var t_7 = t_4.CommonQuote;
} else {
cb(new Error("cannot import 'CommonQuote'")); return;
}
context.setVariable("CommonQuote", t_7);
output += "\n";
env.getTemplate("front/components/CommonBlockVideo/CommonBlockVideo.jinja", false, "assets/app/front/components/CommonBlock/CommonBlock.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "CommonBlockVideo")) {
var t_11 = t_8.CommonBlockVideo;
} else {
cb(new Error("cannot import 'CommonBlockVideo'")); return;
}
context.setVariable("CommonBlockVideo", t_11);
output += "\n";
env.getTemplate("front/components/CommonSlider/CommonSlider.jinja", false, "assets/app/front/components/CommonBlock/CommonBlock.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "CommonSlider")) {
var t_15 = t_12.CommonSlider;
} else {
cb(new Error("cannot import 'CommonSlider'")); return;
}
context.setVariable("CommonSlider", t_15);
output += "\n\n";
var macro_t_16 = runtime.makeMacro(
[], 
["data", "id"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("id", Object.prototype.hasOwnProperty.call(kwargs, "id") ? kwargs["id"] : 0);var t_17 = "";t_17 += "\n    <div class=\"CommonBlock CommonBlock--";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType"), env.opts.autoescape);
t_17 += " isInitialState CommonBlock--";
t_17 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
t_17 += "\" data-id=\"";
t_17 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
t_17 += "\">\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "text") {
t_17 += "\n            ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")), env.opts.autoescape);
t_17 += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "quote") {
t_17 += "\n            ";
t_17 += runtime.suppressValue((lineno = 11, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonQuote"), "CommonQuote", context, [runtime.contextOrFrameLookup(context, frame, "data"),["popup","block"]])), env.opts.autoescape);
t_17 += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "image") {
t_17 += "\n            <div class=\"CommonBlock-img\">\n                <img src=\"";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w940_url"), env.opts.autoescape);
t_17 += "\"\n                     srcset=\"";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w2240_url"), env.opts.autoescape);
t_17 += " 3840w,\n                              ";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w1880_url"), env.opts.autoescape);
t_17 += " 3360w,\n                              ";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w1120_url"), env.opts.autoescape);
t_17 += " 1920w,\n                              ";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w940_url"), env.opts.autoescape);
t_17 += " 1680w,\n                              ";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w520_url"), env.opts.autoescape);
t_17 += " 1120w,\n                              ";
t_17 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")),"w680_url"), env.opts.autoescape);
t_17 += " 720w\"\n                     sizes=\"(max-width: 1119px) 1, ";
t_17 += "\n                             100%\" ";
t_17 += "\n                     alt=\"\">\n            </div>\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")) {
t_17 += "\n                <div class=\"CommonBlock-caption\">\n                    ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")), env.opts.autoescape);
t_17 += "\n                </div>\n            ";
;
}
t_17 += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "video") {
t_17 += "\n            ";
t_17 += runtime.suppressValue((lineno = 31, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonBlockVideo"), "CommonBlockVideo", context, [runtime.contextOrFrameLookup(context, frame, "data")])), env.opts.autoescape);
t_17 += "\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")) {
t_17 += "\n                <div class=\"CommonBlock-caption\">\n                    ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")), env.opts.autoescape);
t_17 += "\n                </div>\n            ";
;
}
t_17 += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "gallery") {
t_17 += "\n            ";
t_17 += runtime.suppressValue((lineno = 38, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonSlider"), "CommonSlider", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"galleryImages")])), env.opts.autoescape);
t_17 += "\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")) {
t_17 += "\n                <div class=\"CommonBlock-caption\">\n                    ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"caption")), env.opts.autoescape);
t_17 += "\n                </div>\n            ";
;
}
t_17 += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"blockType") == "embed") {
t_17 += "\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"embed")) {
t_17 += "\n                ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"embed")), env.opts.autoescape);
t_17 += "\n            ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")) {
t_17 += "\n                ";
t_17 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")), env.opts.autoescape);
t_17 += "\n            ";
;
}
;
}
t_17 += "\n        ";
;
}
;
}
;
}
;
}
;
}
;
}
t_17 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_17);
});
context.addExport("CommonBlock");
context.setVariable("CommonBlock", macro_t_16);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CommonBlock/CommonBlock.jinja"] , dependencies)