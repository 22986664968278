var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/NewsItem/NewsItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/NewsItem/NewsItem.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n<a href=\"";
output += runtime.suppressValue((lineno = 3, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:news-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"id"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"slug")]})])), env.opts.autoescape);
output += "\"\n   class=\"NewsItem u-Route";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img")) {
output += " hasImg";
;
}
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"tag")) {
output += " notTag";
;
}
output += "\">\n    <div class=\"NewsItem-date\">\n        <div class=\"NewsItem-dateMonth\">";
output += runtime.suppressValue(env.getFilter("datetime").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date"),"%B"), env.opts.autoescape);
output += "</div>\n        <div class=\"NewsItem-dateDay\">";
output += runtime.suppressValue(env.getFilter("datetime").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date"),"%-d"), env.opts.autoescape);
output += "</div>\n    </div>\n    <div class=\"NewsItem-info\">\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"tag")) {
output += "\n            <div class=\"NewsItem-infoTag\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"tag"), env.opts.autoescape);
output += "</div>";
;
}
output += "\n        <div class=\"NewsItem-infoTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n    </div>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"cover")) {
output += "\n        <div class=\"NewsItem-img\">\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"cover")),"w220_url"), env.opts.autoescape);
output += "\"\n                 srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"cover")),"w260_url"), env.opts.autoescape);
output += " 2240w,\n                              ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"cover")),"w220_url"), env.opts.autoescape);
output += " 1680w,\n                              ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"cover")),"w135_url"), env.opts.autoescape);
output += " 1120w,\n                     sizes=\" 75%\" ";
output += "\n            alt=\"\">\n        </div>\n    ";
;
}
output += "\n</a>\n";
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/NewsItem/NewsItem.jinja"] , dependencies)